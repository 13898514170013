<template>
  <transition name="slide-bottom">
    <div class="download" v-if="value">
      <div class="download__header">
        <h1 class="download__header-title">{{ $t('download-photo') }}</h1>
        <img @click="$emit('close')" src="@/assets/svg/close/black.svg" alt=""/>
      </div>
      <div class="download__photos">
        <img
            :class="{someone: item.isChecked}"
            @click="checkedPhoto(item.id)"
            alt=""
            v-for="(item, key) in photos" :src="getUserAvatar(item.src, true)"  :key="key"
        />
      </div>
      <div class="download__action">
        <ui-checkbox v-model="isCheckedAll" @input="checkedAll">{{ $t('select-all') }}</ui-checkbox>
        <ui-button color="error" @click="downloadImages">{{ $t('download-photo-length', {data: checkedPhotos.length}) }}</ui-button>
      </div>
    </div>
  </transition>
</template>

<script>
import { getUserAvatar } from "@/utils/user";
// import axios from 'axios';

export default {
  name: "PhotoDownload",
  components: {
    UiCheckbox: () => import('@/components/ui/UiCheckbox'),
    UiButton: () => import('@/components/ui/UiButton'),
  },

  props: {
    images: {
      type: Array,
      default: () => []
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      getUserAvatar,
      photos: [],
      isCheckedAll: false
    }
  },

  methods: {
    checkedPhoto (id) {
      this.photos.forEach(el => {
        if (el.id === id) {
          el.isChecked = !el.isChecked
        }
      })
      if (this.checkedPhotos.length === this.photos.length) {
        this.isCheckedAll = true
      }else {
        this.isCheckedAll = false
      }
    },

    checkedAll () {
      this.photos.forEach(el => el.isChecked = this.isCheckedAll)
      if (this.isCheckedAll) {
        this.photos.forEach(el => el.isChecked = true)
      }else {
        this.photos.forEach(el => el.isChecked = false)
      }
    },

    downloadImages () {
      this.photos.forEach(async (el) => {
        // const url = window.URL.createObjectURL(new Blob([el.fileSrc], { type: 'image/jpeg' }));
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", el.src);
        // document.body.appendChild(link);
        // link.click();

        this.convertUrl(el.fileSrc, function(dataUrl) {
          const link = document.createElement("a");
          link.href = dataUrl;
          link.setAttribute("download", el.src);
          document.body.appendChild(link);
          link.click();
        })
      })
    },

    convertUrl(url, callback) {
      const xhr = new XMLHttpRequest();
      xhr.onload = function() {
        const reader = new FileReader();
        reader.onloadend = function() {
          callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    }
  },

  computed: {
    checkedPhotos() {
      return this.photos.filter((val) => val.isChecked)
    }
  },

  mounted () {
    this.images.map((el,idx) => this.photos.push({
      id: idx,
      src: el.file,
      isChecked: false,
      fileSrc: el.fileSrc,
      name: el.name
    }))
  }
}
</script>

<style lang="scss" scoped>
.download {
  display: flex;
  flex-direction: column;
  gap: 30px;
  background: #FFFFFF;
  padding: 30px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10001;
  transition: 0.5s;

  @media (max-width: 768px) {
    gap: 20px;
    padding: 20px;
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
      align-items: flex-end;
    }

    &-title {
      font-weight: 400;
      font-size: 20px;
      line-height: 22px;
      color: #1B1A1F
    }

    img {
      cursor: pointer;
      width: 35px;
      height: 35px;
    }
  }

  &__photos {
    display: flex;
    gap: 20px;

    @media (max-width: 768px) {
      gap: 10px;
    }

    img {
      max-width: 200px;
      width: 100%;
      height: 100%;
      max-height: 200px;
      border-radius: 10px;
      cursor: pointer;
      border: 1px solid transparent;

      @media (max-width: 768px) {
        width: 84px;
        height: 84px;
      }
    }

    .all {
      border: 1px solid #E21F1F;
    }

    .someone {
      border: 1px solid #E21F1F;
    }
  }

  &__action {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 10px;
    max-width: 420px;
    width: 100%;

    @media (max-width: 768px) {
      gap: 20px;
    }
  }
}
</style>